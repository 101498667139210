<template>
  <div class="input-control">
    <div class="mt-1 px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      <div class="space-y-1 text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div class="text-sm text-gray-600 mx-auto">
          <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-orange-600 hover:text-orange-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500">
            <span>Upload a file</span>
            <input type="hidden" name="model" :value="model" />
            <input type="hidden" name="model_id" :value="model_id" />
            <input type="file" ref="myFiles" @change="previewFiles" multiple="true" />
          </label>
          <p class="pl-1">or drag and drop</p>
        </div>
        <p class="text-xs text-gray-500">
          PNG, JPG, GIF up to 10MB
        </p>
      </div>
      
      <div class="space-y-1 text-center mt-2">
        <div v-for="(f, index) in files" :key="index">
          <div class="file">
            {{ f.file.name }} ({{ f.file.type }})
            <span v-if="f.progress > 0 && f.progress < 100">
              uploading...
            </span>
            <span v-if="f.progress">{{ f.progress }}%</span>
          </div>
        </div>
      </div>
    
    </div>
    
  </div>
</template>

<script>
import UploadService from '../../../services/admin/photos/service';
//import DynamicImage from '../../DynamicImage.vue';

export default {
  //components: { DynamicImage },
  props: {
    value: {
      type: String,
      default: ""
    },
    model: {
      type: String,
      default: ""
    },
    model_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      files: []
    };
  },
  methods: {
    previewFiles() {
      let list = [];
      const fList = this.$refs.myFiles.files;
      for (let i = 0; i < fList.length; i++) {
        list.push({ file: fList[i], progress: 0 });
      }
      this.files = list;

      if (this.files.length > 0) {
        this.submit();
      }
    },
    async submit() {
      //alert(this.files.length);
      for (let i = 0; i < this.files.length; i++) {
        this.upload(this.files[i].file, i);
      }
    },
    async upload(file, index) {
      try {
        
        const data = await UploadService.getCredentials(file.name);

        var options = {
          headers: {
            "Content-Type": file.type
          },
          onUploadProgress: progressEvent => {
            var percentComplete = progressEvent.loaded / progressEvent.total;
            this.files[index].progress = Math.round(percentComplete * 100);
          }
        };

        await UploadService.uploadToS3(data.data.url, file, options);

        await UploadService.attachmentToModel({
          key: data.data.key,
          id: this.model_id,
          mimetype: file.type,
          model: this.model
        })

        
        //- return attachmentLocation.data.src;
        // TODO this emit download done and the component can update the view with that info.
        
        this.$emit("update-add");
      } catch (err) {
        console.log(err);
      }
    },
    
  }
};
</script>

<style lang="scss" scoped="true"></style>
