<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Wijzig item" />

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">

          <form @submit.prevent="handleSave" method="POST" v-if="currentHomepageItem">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-6">
                    <label for="title" class="block text-sm font-medium text-gray-700">Big title</label>
                    <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" required v-model="currentHomepageItem.title">
                  </div>

                  <div class="col-span-6 sm:col-span-6">
                    <label for="subtitle" class="block text-sm font-medium text-gray-700">Smaller subtitle</label>
                    <input type="text" name="title" id="subtitle" autocomplete="subtitle" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" required v-model="currentHomepageItem.subtitle">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last_name" class="block text-sm font-medium text-gray-700">Title button</label>
                    <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="currentHomepageItem.btn_txt">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="company_website" class="block text-sm font-medium text-gray-700">
                      URL button
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        http://
                      </span>
                      <input type="text" name="company_website" id="company_website" class="focus:ring-orange-500 focus:border-orange-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300" placeholder="www.example.com" v-model="currentHomepageItem.btn_link">
                    </div>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="company_website" class="block text-sm font-medium text-gray-700">
                      Published?
                    </label>
                    <div class="mt-1 flex">
                      <button type="button" :class="currentHomepageItem.published ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="updatePublished(!currentHomepageItem.published)">
                        <span class="sr-only">Published</span>
                        <span aria-hidden="true" :class="currentHomepageItem.published ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                      </button>
                    </div>
                  </div>
                  
                  <div class="col-span-6 sm:col-span-3">
                    <label for="order" class="block text-sm font-medium text-gray-700">Order</label>
                    <input type="number" name="order" id="order" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="currentHomepageItem.order">
                  </div>

                </div>

              </div>
              
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="saveItem">
                  Save
                </button>
                <p>{{ message }}</p>
              </div>
            </div>
            <button class="inline-flex w-full justify-center py-2 mt-4 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="deleteItem">
              Delete
            </button>
          </form>
        
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div v-if="currentHomepageItem">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Slide image</h3>
            <p class="mt-1 text-sm text-gray-600">
              Upload a high resolution image. The image will be scaled automatically.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                
                <div>
                  <Uploader model="HomepageItem" :model_id="currentHomepageItem.id" @update-add="myMethod" />  
                  <AttachmentList :attachments="currentHomepageItem.Photos" @update-delete="myMethod" />
                  
                </div>
              </div>
              
            </div>
          
        </div>
      </div>
      
    </div>

  </main>
</template>

<script>
import HompageItemService from '../../../services/admin/homepage_items/service';
import AttachmentList from '../../../components/admin/generic/AttachmentList';
import Uploader from '../../../components/admin/generic/Uploader';

export default {
  name: "Homepage_item",
  data() {
    return {
      currentHomepageItem: null,
      message: ''
    };
  },
  components: {
    AttachmentList,
    Uploader
  },
  methods: {
    getHomepageItem(id) {
      HompageItemService.get(id)
        .then(response => {
          this.currentHomepageItem = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    updatePublished(status) {
      var data = {
        published: status
      };

      HompageItemService.update(this.currentHomepageItem.id, data)
        .then(response => {
          console.log(response.data);
          this.currentHomepageItem.published = status;
          this.message = 'The status was updated successfully!';
        })
        .catch(e => {
          console.log(e);
        });
    },

    saveItem() {
      HompageItemService.update(this.currentHomepageItem.id, this.currentHomepageItem)
        .then(response => {
          console.log(response.data);
          this.message = 'The item was updated successfully!';
        })
        .catch(e => {
          console.log(e);
        });
    },

    deleteItem() {
      HompageItemService.delete(this.currentHomepageItem.id)
        .then(response => {
          console.log(response.data);
          this.$router.push({ name: "Homepage items" });
        })
        .catch(e => {
          console.log(e);
        });
    },
    async myMethod() {
      console.log("Refreshing attachments");
      this.getHomepageItem(this.$route.params.id);
      
    }
  },
  mounted() {
    this.message = '';
    this.getHomepageItem(this.$route.params.id);
  }
};
</script>