import axios from 'axios';
import authHeader from '../../auth-header';
// import router from "../../../router";
// import store from '../../../store'

// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   console.log('---------------------------------------')
//   console.log(error.response.data)
//   if (error.response.status === 401) {
//     store.dispatch('auth/logout')
//     router.push('/login')
//   }
//   return Promise.reject(error)
// })

const API_URL = process.env.VUE_APP_API_BASE_URL;
class UserService {
  getHomepageItems() {
    return axios.get(API_URL + 'admin/homepage_items', { headers: authHeader() });
  }
  
  get(id) {
    return axios.get(API_URL + `admin/homepage_items/${id}`, { headers: authHeader() });
  }

  create(c) {
    return axios.post(API_URL + `admin/homepage_items/`, {...c}, { headers: authHeader() });
  }

  update(id, data) {
    return axios.put(API_URL + `admin/homepage_items/${id}`, {...data}, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + `admin/homepage_items/${id}`, { headers: authHeader() });
  }

}

export default new UserService();
