<template>
  <div class="flex flex-wrap -mx-4 -mb-8 mt-4">
    <div v-for="a in list" :key="a.id" class="md:w-1/4 px-4 mb-8">
            
      <DynamicImage :source="a.data" class="rounded shadow-md" />
      <div class="bg-gray-200 w-full mt-2">
        <span class="rounded mx-2 px-2 bg-red-900 text-white cursor-pointer" @click="deleteImage(a.id)">delete</span>
      </div>

    </div>
  
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import UploadService from '../../../services/admin/photos/service';
import DynamicImage from "../../DynamicImage.vue";

export default {
  components: {
    // draggable,
    DynamicImage
  },
  props: {
    attachments: {
      type: Array
    }
  },
  data: function() {
    return {
      list: this.attachments
    };
  },
  watch: {
    attachments: function (val) {
      this.list = val
    }
  },
  methods: {
    update() {
      console.log(this.list.length);
      this.$store.dispatch("admin/attachments/reorder", this.list).then(() => {
        // this.$router.push("/admin/cases");
      });
    },
    async deleteImage(id) {
      console.log(id)
      await UploadService.deleteImage(id);
      this.$emit("update-delete");
      
    }
  }
};
</script>

<style scoped></style>
